import React, { StrictMode, useMemo } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import {
  ConnectionProvider,
  WalletProvider
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const RootApp = () => {
  // Solana network and wallet setup
  const endpoint = useMemo(() => clusterApiUrl("mainnet-beta"), []);
  const wallets = useMemo(() => [new PhantomWalletAdapter()], []);

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <StrictMode>
            <App />
          </StrictMode>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

root.render(<RootApp />);